export const TRANSLATIONS_EN = {
    price: "40€",
    addressname: "Salon des arts Mairie 29660 CARANTEC",
    emailname: "salonpeinturecarantec29@orange.fr",

    //links:
    linkhome:"Home",
    linkapply:"Apply",
    linkcontact:"Contact Us",
    linkregisterform:"Fill the form online",
    linkregisterdownload:"Form",

    //footer:
    address:"Address : ",
    phone:"Phone : ",
    email:"Email : ",
    followus:"Follow us",

    //home page:
    expo:"Summer Exhibition 2025",
    expodate: "from 13/07 to 17/08",
    expoplace: "Espace André JACQ",
    registrationlimitdate: " Now",
    register:"Register Now!",
    partnership:"Become a partner : ",
    contactus:"Contact us",
    location:"Location",
    beforethe:"",
    presentation1:"Salon des Arts de Carantec is an association under 1901's law.",
    presentation2:"Started in 2011, this association's purpose is to organized exibitions of local paintors and sculptors.",
    presentation3:"All artists are welcome to apply.",
    presentation4:"During the exhibitions, artists are welcomed to do",
    workshop:" workshops",
    presentation5:" and our pationate volunteers will also offer ",
    discovery:"discovery tours",
    presentation6:"If you want more informations, look at posters and pictures of past events,",
    
    //apply page:
    registertitle:"Apply to the next exibition ",
    registerprice:"Price ",
    registerrules:"Rules to register ",
    createpdf:"Create pdf",
    painting:" painting",
    sculpture:" sculpture",

    step2b:"Send pictures of your artwork by ",
    step2c:"New applications: join a small text about you",
    step3:"Wait for an answer form the comitee",
    step2a:"If you got a positive reply, complete the form, save it and send it by mail with a check of ",

    warning:"You could encounter difficulties using mobile devices",

    organisation:"Organisation",
    openning:"Openning to the public :", 
    openningdate:" 13th of July 2025 ",
    openninghours:"Openning hours : from 3pm till 7pm.",
    closure:"End of the exhibition :",
    closuredate:" 17th of August 2025.",
    privateview:" Opening the 12th of July at 6pm.",

    artworkdeposit:"Artwork deposit",
    paintors:"Paintors :",
    sculptors:"Sculptors :",
    datepaintors:" 7th of July from 10am till 12am and from 2pm till 4pm.",
    datesculptors:" 9th of July from 10am till 12am.",
    depositdatepaintors:" ",
    depositdatesculptors:" ",
    deposittext:"The setting up of the artwork is made by our trusted volonteers.",

    artworkretrival:"Artwork retrival",
    retrivaldate:" 18th of August from 10am till 12am.",
    retrivaltext:" ",

    artworkinfos:"Artwork informations",
    infostext:"Submitted artwork must be originals and not have already been presented in our exhibitions. The organisation commitee could choose to limit the number pieces presented. We also need pictures of your artwok (in good definition) to select one for the poster.",
    infospaintors:" Each artist has a available area of 1m20x1m80 (horizontal or vertical). ",
    infossculptors:" 4 or 5 pieces of original artwork. Please be sure to bring white pedestal to present your work.",

    applicationlign1:"The registration fee is ",
    applicationlign2:" to send by check payable to the salon des arts, after recieving the application file and acceptance by the association.",
    applicationaddress:"Use the following address : ",
    applicationrule1:"Registration implies mandatory acceptance of the rules of the salon.",
    applicationrule2:"Registration is not effective untill recieving the complete application file and confirmation by the association.",

    salestext:"Sailing prices are under the responsability of the artist. Each piece should have inscribed in the back : name, firstname, address, title of the piece, price and an authencication certificate signed by the artist. Each piece should be provided with a strong and practile support system. The association will ask for 10% of each sales.",

    insurancestext:"The comitee and volonteers will take the utmost care of your work. The city council and the association will not be responsible for any damage occuring during the exhibition. Artists are strongly invitated to insure their work against all risks. Artists also renounce the rigths to any recourse against the association. Transport of artworks is the responsability of the artist.",

    permanancytext:"Artits are invited to be on site for one or 2 permanencies from 3 to 7pm according to their availabilities. Attendance is a plus to exchange with visitors and boost your sales. Demonstration workshops are possible to set up. Contact us if you are interested. Please consider providing us with business cards to display.", 

    otherstext:"It was decided that the only sales possible at the reception of the salon will be postcards, posters, bookmarks belonging to the association.",

    //sidebar:
    regulation:"Regulation",
    info:"Info",
    artwork:"Artwork",
    applications:"Applications",
    sales:"Sales",
    insurances:"Insurances",
    permanency:"Permanency",
    others:"More",

    //contacts page:
    contacttitle:"Contact us",

    //notfound page:
    notfound:"Page not found, please go back to the home page",
};